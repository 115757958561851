html {
  scroll-behavior: smooth;
}

:root {
  --main-brand-color: #0868f8;
}

._main-photo-text {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  background: #3a86f9;
  padding: 10px 20px;
  transform: translateY(-100%);
}

._change-main-photo {
  display: none;
}

._photo:hover ._change-main-photo {
  cursor: pointer;
  background: #00000033;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

._photo:hover ._change-main-photo button {
  font-family: Montserrat;
  width: 100%;
  padding: 10px 20px;
  background-color: #000000b0;
  border: none;
  color: #fff;
}

._upload-input {
  /* outline: auto; */
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
}

/* ._upload-input * {
  outline: auto;
} */

._delete:hover {
  background-color: rgba(240, 128, 128, 0.168) !important;
  color: rgb(238, 33, 33) !important;
}

.__calender {
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* border: 1px solid gray; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
  border: 1px solid #8d9197;
  /* margin: 10px; */
  border-radius: 10px;
  background: #fff;
  --booked: #c0f2d9;
  --blocked: #fccaca;
  overflow: hidden;
}

.__calender-week-names {
  display: flex;
  justify-content: space-between;
  /* border-radius: 10px 10px 0 0;
  border: 1px solid lightgray; */
  /* background: var(--main-brand-color); */
  /* border: 1px solid gray; */
  border-bottom: 1px solid #8d9197;
  /* background: #accffd; */
  /* color: #fff; */
  text-align: center;
}

.__calender-week-names h3 {
  flex-grow: 1;
  padding: 20px;
  font-weight: 600;
  text-align: left;
  color: var(--main-brand-color);
}

.__calender-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  padding-inline: 20px;
  padding-block-end: 20px;
}

.__calender-days .--empty {
  opacity: 0;
}

.__calender_day {
  width: 100%;
  /* aspect-ratio: 1 / 1; */
  /* border: 1px solid lightgray; */
  /* box-shadow: rgba(0, 0, 0, 0.122) 0px 0px 0px 1px; */
  /* box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset; */
  /* box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; */
  /* box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px; */
  border: 1px solid #8d9197;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.2s ease-in-out;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  /* background: var(--booked); */
}

.__calender_day.--booked {
  background: var(--booked);
  color: #2a9d8f;
  /* font-weight: 600; */
}

.__calender_day.--blocked {
  background: var(--blocked);
  color: #f02929;
  /* font-weight: 600; */
}

.__calender-days .--past {
  opacity: 0.5;
  /* cursor: not-allowed; */
}

.__calender_day.--selected {
  transform: scale(1.05);
  /* outline: 1px solid var(--main-brand-color); */
  outline: 1px solid #000;
  border-color: #000;
}

.__calender_day.--selected.--blocked {
  outline: 1px solid #f02929;
  border-color: #f02929;
}

.__calender_day.--selected.--booked {
  outline: 1px solid #2a9d8f;
  border-color: #2a9d8f;
}

.__calender_day:active {
  transform: scale(0.9);
}

.skiptranslate iframe {
  display: none !important;
}