.accountDetailsPage,
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.container {
  width: 100%;
  max-width: 1300px;
  padding: 20px;
  display: flex;
  gap: 40px;
}

.navContainer {
  width: 100%;
  max-width: 400px;
}

.navContainer nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  border: 1px solid #8b8f95;
  border-radius: 10px;
  overflow: hidden;
}

.navContainer nav li {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  border-bottom: 1px solid #8b8f95;
  cursor: pointer;
  user-select: none;
}

.navContainer nav li.selected {
  background-color: #f2f7fe;
  color: var(--main-brand-color);
  font-weight: 600;
}

.navContainer nav li:last-child {
  border-bottom: none;
}

.navContainer nav li img {
  height: 35px;
  width: auto;
}

.page {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.page .head {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  text-align: center;
  gap: 20px;
  max-width: 800px;
}

.page .head .profilePic {
  height: 100px;
  width: 100px;
  background-color: #767a7f8b;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  position: relative;
}

.page .head .profilePic .icon {
  position: absolute;
  left: 15px;
  bottom: 15px;
  color: #fff;
  height: 25px;
  width: auto;
}

.page .head .info p {
  margin-top: 5px;
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 50px;
}

.loaderContainer .loader {
  width: 30px;
  height: 30px;
  border: 2px solid var(--main-brand-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .page {
    width: 100%;
  }

  .page .head {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .container {
    padding: 40px 20px;
    flex-direction: row;
    align-items: flex-start;
  }
}
