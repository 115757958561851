.fileInput {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 50px;
  aspect-ratio: 1 / 1;
}

.fileInput .inputContainer {
  background: #e9e9eb;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 100%;
  border: 1px solid #e9e9eb;
}

.fileInput .inputContainer .placeholder {
  color: #464d57;
  font-family: var(--primary-font);
  letter-spacing: 1px;
  font-weight: 300;
  font-size: 0.9rem;
}

.fileInput .inputContainer input {
  cursor: pointer;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.fileInput label {
  font-weight: 600;
}

.icon {
  height: 20px;
  width: fit-content;
  color: var(--main-brand-color);
}

.removeIcon {
  cursor: pointer;
  position: absolute;
  height: 20px;
  width: fit-content;
  color: var(--error-clr);
  z-index: 1;
  top: -5px;
  right: -5px;
}

.error {
  width: fit-content;
  text-align: left;
  padding-left: 10px;
  font-size: 0.9rem;
  letter-spacing: 2px;
}

.fileInput.profile {
  height: 100px;
  width: 100px;
  background-position: center;
  background-size: cover;
  position: relative;
}

.fileInput.profile .inputContainer {
  border-radius: 50%;
}

.fileInput.profile .removeIcon {
  display: none;
}

.fileInput.profile .icon {
  pointer-events: none;
  position: absolute;
  color: #fff;
  left: 20px;
  bottom: 20px;
  height: 25px;
  width: fit-content;
}
